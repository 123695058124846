<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-layout wrap>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="rowBg">
            <h4>
              <v-icon color="#BD2121" style="font-size:35px;" class="mr-4"
                >mdi-lock-open-outline</v-icon
              >
              Change Password
            </h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-stepper v-model="e1" alt-labels>
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1" color="#72BD61">
                  Verify identity
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2" color="#72BD61">
                  Change Password
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" color="#72BD61">
                  Finished
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1" style="border:none;">
                  <v-row wrap>
                    <v-col cols="10" offset="1">
                      <v-icon class="mr-5" color="#2776ED"
                        >mdi-alert-circle-outline</v-icon
                      >
                      If you must change your passwrod, then you will have to verify your email address.
                    </v-col>
                  </v-row>

                  <v-row wrap>
                    <v-col cols="12" md="10" offset-md="1">
                      <v-card
                        class="mt-1"
                        height="auto"
                        style="border:2px dotted black !important;padding:30px;"
                      >
                        <v-row>
                          <v-col cols="12" md="4" offset-md="1">
                            <v-text-field
                              dense
                              outlined
                              color="#BD2121"
                              value="chime***em@gmail.com"
                              label="Email Address"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row wrap>
                          <v-col cols="12" md="4" offset-md="1">
                            <v-text-field
                              dense
                              outlined
                              color="#BD2121"
                              placeholder="6 digits"
                              type="text"
                              label="Verification code"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              outlined
                              color="#BD2121"
                              @click="resendEmail(userDetail.email)"
                            >
                              Request code
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row wrap v-if="emailResentStatus">
                          <v-col
                            cols="12"
                            md="1"
                            offset-md="1"
                            class="hidden-sm-and-down"
                          >
                            <v-icon color="#2F8665" size="40"
                              >mdi-check-circle-outline</v-icon
                            >
                          </v-col>
                          <v-col cols="12" md="9">
                            <p>
                              A verification code has been sent to your email
                              address, and it will be valid for 15 minutes.
                              Please also check your junk mail and enter the
                              valid code.
                            </p>
                          </v-col>
                        </v-row>

                         <v-row wrap v-show="successMessage == true">
                          <v-col
                            cols="12" md="10" offset-md="1" 
                          >
                            <v-alert
                            dense
                            border="left"
                            type="success"
                            >
                            Your email verification was succesful. Please click on the button below to continue
                            </v-alert>
                          </v-col>
                        </v-row>

                        <v-row wrap>
                          <v-col cols="12" md="10" offset-md="1">
                            <v-btn
                              class="buttonStyle2"
                              v-if="codeVerified == false"
                              @click.prevent="verifyCode"
                            >
                              Submit
                            </v-btn>

                            <v-btn
                              outlined
                              color=""
                              v-if="codeVerified"
                              @click="e1 = 2"
                            >
                              Continue
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row wrap>
                    <v-col cols="12" md="10" offset-md="1" class="colBg">
                        <h6>Why haven't i received any email verification code?</h6>
                        <p>
                            <ol>
                                <li>Your email code may take up to 10 minutes to arrive (depending on your email service provider), please do not repeat clicking.</li>
                                <li>Please check if your trash/spam folder or your mail inbox is full.</li>
                                <li>Check with your email operator to see if verification code email has been blocked.</li>
                            </ol>
                        </p>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-row wrap>
                    <v-col cols="10" offset="1">
                      <v-icon class="mr-5" color="#2776ED"
                        >mdi-alert-circle-outline</v-icon
                      >
                     Enter Old & New Password to complete process
                    </v-col>
                  </v-row>

                  <v-row wrap>
                    <v-col cols="12" md="10" offset-md="1">
                      <v-card
                        class="mt-1"
                        height="auto"
                        style="border:2px dotted black !important;padding:30px;"
                      >
                        <v-row>
                          <v-col cols="12" md="5" offset-md="1">
                            <v-text-field
                              dense
                              outlined
                              color="#BD2121"
                              placeholder="Enter Old Password"
                              label="Old Password"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="5" offset-md="1">
                            <v-text-field
                              dense
                              outlined
                              color="#BD2121"
                               placeholder="Enter New Password"
                              label="New Password"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>



                        <v-row wrap>
                          <v-col cols="12" md="10" offset-md="1">
                            <v-btn
                              class="buttonStyle2"
                              @click="e1 = 3"
                            >
                              Submit
                            </v-btn>

                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row wrap>
                    <v-col cols="12" md="10" offset-md="1" class="colBg">
                        <h6>Quick Tip</h6>
                        <p>
                            <ol>
                                <li>Make sure the new email address is spelled correctly, so verification can be done</li>
                                <li>Please check if your trash/spam folder or your mail inbox is full.</li>
                                <li>Check with your email operator to see if verification code email has been blocked</li>
                            </ol>
                        </p>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-row wrap>
                    <v-col cols="10" offset="1">
                      <v-icon class="mr-5" color="#2776ED"
                        >mdi-alert-circle-outline</v-icon
                      >
                     Congratulations, password has been change successfully
                    </v-col>
                  </v-row>

                  
                  <v-row wrap>
                          <v-col cols="12" md="10" offset-md="1">
                            <v-btn
                              class="buttonStyle2"
                              @click.prevent="verifyNewEmailCode"
                            >
                              Done
                            </v-btn>
                          </v-col>
                  </v-row>

                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Emailupdate",

  data: () => ({
    e1: 1,
    dialogUploadPicture: false,
    dialogEditProfile: false,
    dialogChangeEmail: false,
    emailResentStatus: false,
    preview: false,
    codeVerified: false,
    codeVerified2: false,
    decodedEmail: "",
    imageLoader: false,
    newEmail:"",
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/retailer_dashboard/home",
      },
      {
        text: "Settings",
        disabled: false,
        href: "/#/retailer/account/settings",
      },
      {
        text: "Email Update",
        disabled: true,
        href: "/",
      },
    ],
    UpdateImage: {
      id: "",
      profileImage: "",
    },
    userDetail: {
      account: "B2B Shopper",
      gender: "Male",
      email: "chime.emmanuel@gmail.com",
      memberID: "Ng770464159ofeae",
      contactAddress: "Nigeria",
      zipcode: "440433",
      phone: "08062159230",
    },
    successMessage: false,
    code:"",
  }),
  mounted() {
    this.decodeEmail(this.userDetail.email);
  },
  methods: {
    onPickAvatar() {
      this.imageLoader = true;
      this.$refs.fileInput.click();
    },
    avatarPicked(e) {
      var fileReader = new FileReader();

      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        this.preview = true;
        this.imageLoader = false;
        this.UpdateImage.profileImage = e.target.result;
      };
      // console.log(this.artisanForm1Data)
    },
    resendEmail(email) {
      console.log(email);
      this.emailResentStatus = true;
    },

    resendCode(email) {
      console.log(email);
      this.code = "342534";
      this.emailResentStatus = true;
    },

    decodeEmail(email) {
      const partialEmail = email.replace(
        /(\w{5})[\w.-]+@([\w.]+\w)/,
        "$1*******@$2"
      );
      this.decodeEmail = partialEmail;
      console.log(partialEmail);
    },

    verifyCode() {
      this.codeVerified = true;
      this.emailResentStatus = false;
      this.successMessage = true;
    },

    verifyNewEmailCode() {
      this.codeVerified2 = true;
      this.emailResentStatus = false;
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px 0px 5px 20px;
}

.colBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 25px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.buttonStyle2 {
  background: linear-gradient(180.88deg, #72bd61 33.53%, #111212 95.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-left: 25px;
}

li {
  font-size: 12px;
  margin-top: 5px;
}

.defaultAddressStyle {
  width: 130px;
  height: auto;
  background: rgba(17, 18, 18, 0.47);
  color: #ffffff;
  padding: 5px;
  border-top-left-radius: 5px;
}

.photoBorderStyle {
  border: 1px solid #4176c7;
  padding: 5px;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.resendEmailStyle {
  background-color: #fafafa;
  padding: 10px;
  color: black;
}

.noteStyle {
  background-color: #c7c3a6;
  padding: 15px;
  color: #000000;
}
</style>
